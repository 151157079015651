<template>
  <a-modal
    title="计费项目"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" @submit="handleSubmit">
      <a-form-item label="项目名称">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入项目名称' }]}]"/>
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-decorator="['remark']"/>
      </a-form-item>
      </a-form>
  </a-modal>
</template>
<script>
import { chargeItemsCreate, chargeItemsEdit } from '@/api/charging'

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      model: {},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    show (record) {
      this.visible = true
      if (record) {
        this.model = record
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: record.name,
            remark: record.remark
          })
        })
      }
    },
    handleOk () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          if (this.model.id) {
            chargeItemsEdit(Object.assign(values, { id: this.model.id })).then(data => {
              this.$message.success('修改成功')
              this.$emit('ok')
              this.handleCancel()
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            chargeItemsCreate(values).then(data => {
              this.$message.success('创建成功')
              this.$emit('ok')
              this.handleCancel()
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.model = {}
      this.visible = false
    }
  }
}
</script>